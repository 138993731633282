import React, { useState } from 'react';
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';

import Select from 'components/commons/select';
import KeypadForm from './KeypadForm';
import TimeForm from './TimeForm';
import PickerForm from './PickerForm';
import TextForm from './TextForm';
import { SURVEY_OPTION_INPUT_TYPES } from 'data/surveys';
import useStyles from './SingleAnswerOptions.styles';

const inputTypeComponents = {
  keypad: { component: KeypadForm, unnecessaryFields: ['text', 'inputIs24Hr', 'inputIncrement'] },
  time: {
    component: TimeForm,
    unnecessaryFields: ['text', 'inputMin', 'inputMax', 'inputIncrement']
  },
  picker: { component: PickerForm, unnecessaryFields: ['text', 'inputIs24Hr'] },
  text: {
    component: TextForm,
    unnecessaryFields: ['inputMin', 'inputMax', 'inputIncrement', 'inputIs24Hr']
  }
};

const SingleAnswerOptions = ({ onChange, hidden, options }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogOption, setDialogOption] = useState({});

  const handleOpenDialog = optionKey => () => {
    const newDialogOption = options[optionKey];
    setDialogOption({ optionKey, ...newDialogOption });
    setOpenDialog(true);
  };

  const handleInputTypeChange = optionKey => ({ target: { value } }) => {
    const optionLocal = options[optionKey];
    const selectedType = inputTypeComponents[value];
    selectedType.unnecessaryFields.forEach(field => (optionLocal[field] = ''));
    optionLocal['inputType'] = value;
    onChange(`options[${optionKey}]`)({ target: { value: optionLocal } });
  };

  return (
    <>
      {options
        ? options.map((option, optionKey) => {
            const InputTypeForm = inputTypeComponents[option.inputType] || '';
            const InputTypeFormComponent = InputTypeForm ? (
              <InputTypeForm.component onChange={onChange} option={{ optionKey, ...option }} />
            ) : null;
            return (
              <div key={optionKey}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <Select
                      id="type"
                      label="Input type"
                      onChange={handleInputTypeChange(optionKey)}
                      value={option.inputType}
                      options={SURVEY_OPTION_INPUT_TYPES}
                      margin="normal"
                      required
                      fullWidth
                    />
                  </Grid>
                  {!hidden && (
                    <Grid item xs={1} className={classes.moreContainer}>
                      <IconButton onClick={handleOpenDialog(optionKey)}>
                        <MoreIcon size="small" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
                {InputTypeFormComponent}
              </div>
            );
          })
        : null}
      <OptionsDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onChange={onChange}
        option={dialogOption}
      />
    </>
  );
};

const OptionsDialog = ({ open, onClose, onChange, option }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Extra fields</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Next question"
          type="text"
          value={option.nextQuestion}
          onChange={onChange(`options[${option.optionKey}].nextQuestion`)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SingleAnswerOptions;
