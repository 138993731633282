import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  IconButton
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Button from 'components/commons/button';
import Select from 'components/commons/select';
import Form from 'components/commons/form';
import { SettingsTab, ParametersTab, StringsTab } from '../tabs';
import { PLEDGE_CATEGORIES, PLEDGE_TYPES, PLEDGE_STATUSES } from 'data/pledges';
import useStyles from './Form.styles';

const tabs = [SettingsTab, ParametersTab, StringsTab];

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    <Box p={3}>{children}</Box>
  </Typography>
);

const PledgeForm = ({ facts, form, handleChange, handleCheck, labels, onSubmit }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const a11yProps = index => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  });

  const handleTabChange = (e, newValue) => setTabValue(newValue);

  return (
    <Form className={classes.form} onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Select
            label="Category"
            id="category"
            onChange={handleChange('category')}
            value={form.category}
            options={PLEDGE_CATEGORIES}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            label="Type"
            id="type"
            onChange={handleChange('type')}
            value={form.type}
            options={PLEDGE_TYPES}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            label="Status"
            id="status"
            onChange={handleChange('status')}
            value={form.status}
            options={PLEDGE_STATUSES}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            label="Name"
            type="text"
            value={form.name}
            onChange={handleChange('name')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="priority"
            label="Priority"
            type="text"
            value={form.priority}
            onChange={handleChange('priority')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Value between 0 and 200." placement="top-start">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Title"
            type="text"
            value={form.headerText}
            onChange={handleChange('headerText')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Body"
            type="text"
            value={form.bodyText}
            onChange={handleChange('bodyText')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Accept"
            type="text"
            value={form.acceptText}
            onChange={handleChange('acceptText')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Decline"
            type="text"
            value={form.declineText}
            onChange={handleChange('declineText')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Button color="primary" variant="contained" type="submit" size="medium">
        Save
      </Button>
      <AppBar elevation={0} position="static" className={classes.tabs}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label="Settings" {...a11yProps(0)} />
          <Tab label="Parameters" {...a11yProps(1)} />
          <Tab label="Strings" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {tabs.map((TabComponent, index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          <TabComponent
            classes={classes}
            form={form}
            handleChange={handleChange}
            handleCheck={handleCheck}
            facts={facts.facts}
            labels={labels.labels}
          />
        </TabPanel>
      ))}
    </Form>
  );
};

export default PledgeForm;
