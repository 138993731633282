import * as data from 'data/pledges';

export const initState = {
  pledge: {},
  isFetching: false,
  lastUpdated: null,
  error: null
};

export default (state = initState, action) => {
  switch (action.type) {
    case data.TYPE_REQUEST_PLEDGE:
      return {
        isFetching: true,
        lastUpdated: null,
        error: null,
        pledge: {}
      };
    case data.TYPE_RECEIVE_PLEDGE:
      return {
        isFetching: false,
        lastUpdated: action.lastUpdated,
        error: null,
        pledge: action.pledge,
        testPassed: action.testPassed,
        testResult: action.testResult
      };
    case data.TYPE_RECEIVE_PLEDGE_ERROR:
      return {
        ...state,
        isFetching: false,
        lastUpdated: null,
        error: action.error
      };
    case data.TYPE_UPDATE_PLEDGE:
      return {
        ...state,
        isFetching: true,
        lastUpdated: null,
        error: null
      };
    case data.TYPE_UPDATE_PLEDGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        error: null
      };
    case data.TYPE_UPDATE_PLEDGE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case data.TYPE_CREATE_PLEDGE:
      return {
        ...state,
        pledge: action.pledge,
        isFetching: true,
        lastUpdated: null,
        error: null
      };
    case data.TYPE_CREATE_PLEDGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        lastUpdated: action.lastUpdated,
        error: null
      };
    case data.TYPE_CREATE_PLEDGE_ERROR:
      return {
        ...state,
        isFetching: false,
        lastUpdated: null,
        error: action.error
      };
    case data.TYPE_RESET_PLEDGE:
      return {
        ...initState
      };
    default:
      return state;
  }
};
