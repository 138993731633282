import React, { useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Input,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Clear';
import MoreIcon from '@material-ui/icons/MoreVert';

import OptionInput from 'components/views/surveys/option-input';
import useStyles from './MultipleChoiceOptions.styles';

const MultipleChoiceOptions = ({
  onChange,
  hidden,
  options,
  nextQuestion,
  selectedOption,
  onClickOption
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogOptionKey, setDialogOptionKey] = useState(0);

  const handleAddOption = () => {
    const newOption = {
      text: 'Option text',
      value: '',
      inputType: 'default',
      position: options.length + 1,
      nextQuestion
    };
    const newOptions = [...options, newOption];
    onChange('options')({ target: { value: newOptions } });
  };

  const handleDeleteOption = deleteKey => () => {
    const newOptions = options.filter((_, optionKey) => deleteKey !== optionKey);
    onChange('options')({ target: { value: newOptions } });
  };

  const handleOpenDialog = optionKey => () => {
    setDialogOptionKey(optionKey);
    setOpenDialog(true);
  };

  return (
    <>
      {options
        ? options.map((option, optionKey) => {
            const isSelected = optionKey === selectedOption;
            return (
              <div key={optionKey} onClick={onClickOption(optionKey)}>
                <Grid container>
                  <Grid item xs={10}>
                    <OptionInput
                      classes={{ root: classes.option }}
                      value={options[optionKey].text}
                      onChange={onChange(`options[${optionKey}].text`)}
                      type="text"
                      margin="dense"
                      hidden={!isSelected}
                      fullWidth
                    />
                  </Grid>
                  {isSelected && (
                    <>
                      <Grid item xs={1} className={classes.deleteOptionContainer}>
                        <IconButton onClick={handleOpenDialog(optionKey)}>
                          <MoreIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={1} className={classes.deleteOptionContainer}>
                        <IconButton onClick={handleDeleteOption(optionKey)}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </>
                  )}
                </Grid>
                {isSelected && (
                  <Grid className={classes.aditionalFieldsContainer} container>
                    <Grid item xs={4}>
                      <Typography>Next question for this option:</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        margin="dense"
                        type="text"
                        value={option?.nextQuestion}
                        onChange={onChange(`options[${optionKey}].nextQuestion`)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}
              </div>
            );
          })
        : null}
      {!hidden && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Button color="primary" onClick={handleAddOption} size="small">
              Add Option
            </Button>
          </Grid>
        </Grid>
      )}
      {!!options.length && (
        <OptionsDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onChange={onChange}
          optionKey={dialogOptionKey}
          option={options[dialogOptionKey]}
        />
      )}
    </>
  );
};

const OptionsDialog = ({ open, onClose, onChange, option, optionKey }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Option extra fields</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Option position (index)"
          type="text"
          value={option?.position}
          onChange={onChange(`options[${optionKey}].position`)}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          label="Option value"
          type="text"
          value={option?.value}
          onChange={onChange(`options[${optionKey}].value`)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultipleChoiceOptions;
