import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import Autocomplete from 'components/commons/autocomplete';
import Select from 'components/commons/select';
import InsightParams from 'components/commons/params-field';
import ConditionsField from 'components/commons/conditions-field';
import TestsField from 'components/commons/tests-field';

const LabelsAndParamsTab = ({ facts, form, handleChange, labels }) => {
  const makeLabelOption = label => {
    if (!label) return null;
    return {
      value: label.id,
      text: label.name
    };
  };

  const labelOptions = Object.values(labels).map(makeLabelOption);
  const factOptions = facts.map(fact => ({ label: fact.value }));

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom>
            Labels
          </Typography>
          <Typography variant="body1" gutterBottom>
            Labels contain conditions or rules that control when certain content should display to
            which users.
          </Typography>
          <Select
            label="Select labels"
            id="labels"
            onChange={handleChange('labels')}
            value={form.labels}
            options={labelOptions}
            multiple
            fullWidth
          ></Select>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom>
            Facts
          </Typography>
          <Typography variant="body1" gutterBottom>
            Add all facts to be used in the calculated parameter values.
          </Typography>
          <Autocomplete
            label="facts"
            placeholder="Search for facts"
            suggestions={factOptions}
            initialValue={form.facts}
            onChange={handleChange('facts')}
            margin="normal"
            multiple
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Parameters
          </Typography>
          <Typography variant="body1" gutterBottom>
            {'Parameters will auto-fill; however, you must add formatting instructions & remove '}
            {'unneeded items manually.'}
          </Typography>
          <InsightParams handleChange={handleChange('params')} initialValue={form.params} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <ConditionsField handleChange={handleChange} initialValue={form.conditions} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <TestsField handleChange={handleChange} initialValue={form.tests} />
        </Grid>
      </Grid>
    </>
  );
};

export default LabelsAndParamsTab;
