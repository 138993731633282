import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Button from 'components/commons/button';
import Form from 'components/commons/form';
import Select from 'components/commons/select';
import {
  MetadataTab,
  LabelsAndParamsTab,
  ABTestingAndGroupingTab,
  TracksTab,
  NotificationsTab,
  TranslationsTab,
  AppTab
} from '../tabs';
import ShortInsightForm from '../short-insight-form';
import LongInsightForm from '../long-insight-form';
import { INSIGHT_CATEGORIES, INSIGHT_STATUSES, INSIGHT_TYPE } from 'data/insights';
import useStyles from './Form.styles';

const insightTypeForms = {
  '0': { component: ShortInsightForm, unnecessaryFields: ['bodyHtml', 'referencesHtml'] },
  '1': { component: LongInsightForm, unnecessaryFields: ['linkSource'] },
  '2': { component: null, unnecessaryFields: ['bodyHtml', 'referencesHtml', 'linkSource'] }
};

const tabs = [
  MetadataTab,
  LabelsAndParamsTab,
  ABTestingAndGroupingTab,
  TracksTab,
  NotificationsTab,
  TranslationsTab,
  AppTab
];

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    <Box p={2}>{children}</Box>
  </Typography>
);

const InsightForm = ({ facts, form, handleChange, handleCheck, labels, onSubmit }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const handleSubmit = () => {
    const { unnecessaryFields } = insightTypeForms[form.type];
    unnecessaryFields.forEach(field => handleChange(field)({ target: { value: '' } }));
    onSubmit();
  };

  const handleTabChange = (e, newValue) => setTabValue(newValue);

  const a11yProps = index => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  });

  const TypeForm = insightTypeForms[form.type] || '';
  const TypeFormComponent = TypeForm?.component ? (
    <TypeForm.component onChange={handleChange} form={form} />
  ) : null;

  return (
    <Form className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Select
            label="Category"
            id="category"
            onChange={handleChange('category')}
            value={form.category}
            options={INSIGHT_CATEGORIES}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            label="Type"
            id="type"
            onChange={handleChange('type')}
            value={form.type}
            options={INSIGHT_TYPE}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            label="Status"
            id="status"
            onChange={handleChange('status')}
            value={form.status}
            options={INSIGHT_STATUSES}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            id="name"
            label="Name"
            type="text"
            value={form.name}
            onChange={handleChange('name')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="priority"
            label="Priority"
            type="text"
            value={form.priority}
            onChange={handleChange('priority')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Value between 0 and 200." placement="top-start">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="ttl"
            label="Time To Live (TTL)"
            type="text"
            value={form.ttl}
            onChange={handleChange('ttl')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Amount of time in days the insight should be displayed."
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="cooldown"
            label="Cooldown"
            type="text"
            value={form.cooldown}
            onChange={handleChange('cooldown')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Amount of time in days between showing the insight for the first time, and showing it again."
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="title"
            label="Title"
            type="text"
            value={form.title}
            onChange={handleChange('title')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="body"
            label="Body"
            type="text"
            value={form.body}
            onChange={handleChange('body')}
            margin="normal"
            multiline
            fullWidth
            required
          />
        </Grid>
      </Grid>
      {!!form.type && TypeFormComponent}
      <Button color="primary" variant="contained" type="submit" size="medium">
        Save
      </Button>
      <AppBar className={classes.tabs} elevation={0} position="static">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Metadata" {...a11yProps(0)} />
          <Tab label="Labels & Params" {...a11yProps(1)} />
          <Tab label="AB Testing & Grouping" {...a11yProps(2)} />
          <Tab label="Tracks" {...a11yProps(3)} />
          <Tab label="Notifications" {...a11yProps(4)} />
          <Tab label="Translations" {...a11yProps(5)} />
          <Tab label="App" {...a11yProps(6)} />
        </Tabs>
      </AppBar>
      {tabs.map((TabComponent, index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          <TabComponent
            form={form}
            handleChange={handleChange}
            handleCheck={handleCheck}
            facts={facts.facts}
            labels={labels.labels}
          />
        </TabPanel>
      ))}
    </Form>
  );
};

export default InsightForm;
