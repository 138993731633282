import React, { useState, useEffect } from 'react';
import { update, constant } from 'lodash';
import Typography from '@material-ui/core/Typography';

import InsightForm from 'components/views/insights/form';
import useStyles from './CreateInsight.styles';

const CreateInsight = ({ actions, facts, insights, labels }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    cooldown: '60',
    name: '',
    type: '',
    priority: '10',
    category: '0',
    status: '0',
    title: '',
    body: '',
    linkSource: '',
    bodyHtml: '',
    referencesHtml: '',
    clientType: '',
    isSmartCoach: false,
    doNotTranslate: false,
    clientConditions: false,
    themeAgnostic: false,
    labels: [],
    facts: [],
    params: {},
    abTestName: '',
    abTestSide: '',
    identifier: '',
    groupName: '',
    watchHead: '',
    watchBody: '',
    hasNotification: false,
    headAlt: '',
    bodyAlt: '',
    appId: '',
    appXid: '',
    conditions: [],
    ttl: '',
    tests: {
      conditions: [],
      params: []
    }
  });
  useEffect(() => {
    actions.onMount();
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = name => e => setForm({ ...update(form, name, constant(e.target.value)) });

  const handleCheck = name => e => setForm({ ...update(form, name, constant(e.target.checked)) });

  const handleSubmit = () => actions.createInsight(form);

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create Insight</Typography>
      <InsightForm
        facts={facts}
        form={form}
        handleChange={handleChange}
        handleCheck={handleCheck}
        labels={labels}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CreateInsight;
