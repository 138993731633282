export default class InsightFactory {
  static toDTO(model) {
    return {
      id: model.id,
      category: parseInt(model.category),
      priority: parseInt(model.priority),
      type: parseInt(model.type),
      cooldown: parseInt(model.cooldown),
      status: parseInt(model.status),
      name: model.name,
      title: model.title,
      body: model.body,
      link_source: model.linkSource,
      body_html: model.bodyHtml,
      references_html: model.referencesHtml,
      client_type: model.clientType,
      is_smart_coach: model.isSmartCoach,
      do_not_translate: model.doNotTranslate,
      client_conditions: parseInt(model.clientConditions) ?? undefined,
      theme_agnostic: model.themeAgnostic,
      labels: model.labels,
      facts: model.facts,
      params: model.params,
      ab_test_name: model.abTestName,
      ab_test_side: model.abTestSide,
      identifier: model.identifier,
      groupName: model.groupName,
      segment_anchor: model.segmentAnchor,
      watch_head: model.watchHead,
      watch_body: model.watchBody,
      has_notification: model.hasNotification,
      head_alt: model.headAlt,
      body_alt: model.bodyAlt,
      app_id: parseInt(model.appId) ?? undefined,
      app_xid: parseInt(model.appXid) ?? undefined,
      tests: model.tests,
      conditions: model.conditions,
      ttl: parseInt(model.ttl)
    };
  }

  static toModel(dto) {
    return {
      id: dto.id,
      category: dto.category?.toString() ?? '',
      priority: dto.priority?.toString() ?? '',
      type: dto.type?.toString() ?? '',
      cooldown: dto.cooldown?.toString() ?? '',
      name: dto.name,
      title: dto.title,
      body: dto.body,
      linkSource: dto.link_source,
      bodyHtml: dto.body_html,
      referencesHtml: dto.references_html,
      clientType: dto.client_type ?? '',
      isSmartCoach: Boolean(dto.is_smart_coach),
      doNotTranslate: Boolean(dto.do_not_translate),
      clientConditions: dto.client_conditions?.toString() ?? false,
      themeAgnostic: Boolean(dto.theme_agnostic),
      labels: dto.labels ?? [],
      facts: dto.facts ?? [],
      params: dto.params ?? {},
      abTestName: dto.ab_test_name ?? '',
      abTestSide: dto.ab_test_side ?? '',
      identifier: dto.identifier ?? '',
      groupName: dto.groupName ?? '',
      segmentAnchor: Boolean(dto.segment_anchor),
      watchHead: dto.watch_head ?? '',
      watchBody: dto.watch_body ?? '',
      hasNotification: Boolean(dto.has_notification),
      headAlt: dto.head_alt ?? '',
      bodyAlt: dto.body_alt ?? '',
      appId: dto.app_id?.toString() ?? '',
      appXid: dto.app_xid?.toString() ?? '',
      tests: dto.tests,
      status: dto.status?.toString() ?? '',
      conditions: dto.conditions,
      ttl: dto.ttl?.toString() ?? ''
    };
  }
}
