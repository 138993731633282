import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import Autocomplete from 'components/commons/autocomplete';
import { Clips } from 'components/commons/code';
import Params from 'components/commons/params-field';

const ParametersTab = ({ classes, facts, form, handleChange }) => {
  const [rules, setRules] = useState(['']);

  const handleRuleChange = ruleKey => value => {
    const newRules = rules.map((rule, index) => {
      if (index !== ruleKey) return rule;
      return value;
    });
    setRules(newRules);
    handleChange('rhsLogic')({ target: { value: newRules } });
  };

  const handleAddRule = () => {
    const newRules = [...rules, ''];
    setRules(newRules);
    handleChange('rhsLogic')({ target: { value: newRules } });
  };

  const handleDeleteRule = ruleKey => {
    const newRules = rules.filter((_, index) => ruleKey !== index);
    setRules(newRules);
    handleChange('rhsLogic')({ target: { value: newRules } });
  };

  const renderRule = (value, index) => (
    <Box key={index} display="flex" alignItems="center" mt={1} mb={1}>
      <Clips name={`rhsLogic-${index}`} value={value} onChange={handleRuleChange(index)} />
      <IconButton onClick={() => handleDeleteRule(index)}>
        <ClearIcon />
      </IconButton>
    </Box>
  );

  const factOptions = facts.map(fact => ({ label: fact.value }));
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography className={classes.titleSpacing} variant="h6" gutterBottom>
            Right Hand Side Parameters
          </Typography>
          <Typography variant="body2" gutterBottom>
            These parameters can be set to display to a user the realtime values of the declared
            facts.
          </Typography>
          <Autocomplete
            placeholder="Search for facts"
            suggestions={factOptions}
            onChange={handleChange('rhsParams')}
            margin="normal"
            multiple
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Typography className={classes.titleSpacing} variant="h6" gutterBottom>
            Extra Parameters
          </Typography>
          <Typography variant="body2" gutterBottom>
            {
              'These parameters can be set to display to a user the "saved" value of a declared fact, '
            }
            {'meaning the value that was present upon first display of the pledge.'}
          </Typography>
          <Params handleChange={handleChange('extraParams')} initialValue={form.extraParams} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.titleSpacing} variant="h6" gutterBottom>
            Right Hand Side Logic
          </Typography>
          <Typography variant="body2" gutterBottom>
            {'Additional logic can be added to the Right Hand Side of the generated rule. '}
            {
              "For example, you could alter the value of the pledge goal based on the user's age or gender."
            }
          </Typography>
          <Typography variant="body2" gutterBottom>
            Or you know like, ask Christian or something.
          </Typography>
          {rules.map(renderRule)}
          <Button color="secondary" onClick={handleAddRule}>
            Add Rule
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.titleSpacing} variant="h6" gutterBottom>
            Default Parameters
          </Typography>
          <Typography variant="body2" display="inline">
            Default parameters for the pledge.
          </Typography>
          <List disablePadding>
            {['goal', 'value', 'delta', 'teammate_name'].map((value, index) => (
              <div key={index}>
                {!!index && <Divider component="li" />}
                <ListItem>
                  <ListItemText primary={value} primaryTypographyProps={{ color: 'secondary' }} />
                </ListItem>
              </div>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default ParametersTab;
