import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createSurvey } from 'actions/survey';
import { CreateSurvey } from 'views';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        createSurvey
      },
      dispatch
    )
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSurvey);
