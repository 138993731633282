import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';

import * as service from 'services/surveys';
import * as data from 'data/surveys';

const requestSurvey = () => ({
  type: data.TYPE_REQUEST_SURVEY
});

const receiveSurvey = json => ({
  type: data.TYPE_RECEIVE_SURVEY,
  survey: json.survey,
  testPassed: json.meta.tests,
  testResults: isEmpty(json.tests) ? [] : json.tests.conditions
});

const receiveSurveyError = error => ({
  type: data.TYPE_RECEIVE_SURVEY_ERROR,
  error: error
});

const postCreateSurvey = survey => ({
  type: data.TYPE_CREATE_SURVEY,
  survey
});

const postCreateSurveySuccess = (survey, json) => ({
  type: data.TYPE_CREATE_SURVEY_SUCCESS,
  survey
});

const postCreateSurveyError = (survey, error) => ({
  type: data.TYPE_CREATE_SURVEY_ERROR,
  error,
  survey
});

const postUpdateSurvey = survey => ({
  type: data.TYPE_UPDATE_SURVEY,
  survey
});

const postUpdateSurveySuccess = (survey, json) => ({
  type: data.TYPE_UPDATE_SURVEY_SUCCESS,
  survey: survey
});

const postUpdateSurveyError = (survey, error) => ({
  type: data.TYPE_UPDATE_SURVEY_ERROR,
  error
});

export const resetSurvey = () => ({
  type: data.TYPE_RESET_SURVEY
});

export const fetchSurvey = id => {
  return dispatch => {
    dispatch(requestSurvey());
    return service
      .fetchSurvey(id)
      .then(response => dispatch(receiveSurvey(response)))
      .catch(error => dispatch(receiveSurveyError(error)));
  };
};

export const createSurvey = survey => {
  return dispatch => {
    dispatch(postCreateSurvey(survey));
    return service
      .createSurvey(survey)
      .then(response => {
        dispatch(postCreateSurveySuccess(survey, response));
        dispatch(push('/surveys'));
      })
      .catch(error => dispatch(postCreateSurveyError(survey, error)));
  };
};

export const updateSurvey = survey => {
  return dispatch => {
    dispatch(postUpdateSurvey(survey));
    return service
      .updateSurvey(survey)
      .then(response => {
        dispatch(postUpdateSurveySuccess(survey, response));
        dispatch(push('/surveys'));
      })
      .catch(error => dispatch(postUpdateSurveyError(survey, error)));
  };
};
