import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { createInsight, resetInsight } from 'actions/insight';
import { fetchLabelsIfNeeded } from 'actions/labels';
import { fetchFactsIfNeeded } from 'actions/facts';
import * as insightsService from 'services/insights';

import { CreateInsight } from 'views';

const mapStateToProps = state => ({
  facts: state.facts,
  insights: state.insights,
  labels: state.labels
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        createInsight: createInsight,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchLabelsIfNeeded({ fields: ['id', 'name'] }));
      dispatch(fetchFactsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetInsight());
    }
  },
  services: {
    fetchAuthors: insightsService.fetchAuthors
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateInsight);
