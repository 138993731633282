import { differenceInDays } from 'date-fns';
import { replace } from 'connected-react-router';
import {
  AUTOLOGIN_REQUESTED,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_REQUESTED,
  LOGOUT_SUCCESS
} from 'reducers/auth';

import * as authService from 'services/auth';

export const login = credentials => {
  return dispatch => {
    dispatch({ type: LOGIN_REQUESTED });
    return authService
      .login(credentials)
      .then(userData => {
        dispatch({ type: LOGIN_SUCCESS, payload: userData });
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('token', userData.token);
        dispatch(replace('/home'));
        return userData;
      })
      .catch(error => {
        dispatch({ type: LOGIN_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const autoLogin = () => {
  return dispatch => {
    dispatch({ type: AUTOLOGIN_REQUESTED });
    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      const difference = differenceInDays(parsedUserData.exp * 1000, Date.now());
      if (difference > 0) {
        dispatch({ type: LOGIN_SUCCESS, payload: parsedUserData });
      }
    } else {
      const error = 'Your session has expired, please log in again.';
      dispatch({ type: LOGIN_FAILED, payload: error });
      return new Error(error);
    }
  };
};

export const logout = () => {
  return dispatch => {
    dispatch({ type: LOGOUT_REQUESTED });
    localStorage.removeItem('userData');
    dispatch({ type: LOGOUT_SUCCESS });
    dispatch(replace('/login'));
  };
};
