import isEmpty from 'lodash/isEmpty';
import * as data from 'data/pledges';
import * as service from 'services/pledges';
import { push } from 'connected-react-router';

const requestPledge = id => ({
  type: data.TYPE_REQUEST_PLEDGE,
  pledge: { id }
});

const receivePledge = json => ({
  type: data.TYPE_RECEIVE_PLEDGE,
  pledge: json.data,
  lastUpdated: json.meta.time,
  testPassed: json.meta.tests,
  testResults: isEmpty(json.tests) ? [] : json.tests.conditions
});

const receivePledgeError = error => ({
  type: data.TYPE_RECEIVE_PLEDGE_ERROR,
  error
});

const postUpdatePledge = pledge => ({
  type: data.TYPE_UPDATE_PLEDGE,
  pledge
});

const postUpdatePledgeSuccess = (pledge, json) => ({
  type: data.TYPE_UPDATE_PLEDGE_SUCCESS,
  lastUpdated: json.meta.time,
  pledge
});

const postUpdatePledgeError = (pledge, error) => ({
  type: data.TYPE_UPDATE_PLEDGE_ERROR,
  error,
  pledge
});

const postCreatePledge = pledge => ({
  type: data.TYPE_CREATE_PLEDGE,
  pledge
});

const postCreatePledgeSuccess = (pledge, json) => ({
  type: data.TYPE_CREATE_PLEDGE_SUCCESS,
  lastUpdated: json.meta.time,
  pledge
});

const postCreatePledgeError = (pledge, error) => ({
  type: data.TYPE_CREATE_PLEDGE_ERROR,
  error,
  pledge
});

export const resetPledge = () => ({
  type: data.TYPE_RESET_PLEDGE
});

export const fetchPledge = id => dispatch => {
  dispatch(requestPledge(id));
  return service
    .fetchPledge(id)
    .then(pledge => dispatch(receivePledge(pledge)))
    .catch(reason => dispatch(receivePledgeError(reason)));
};

export const updatePledge = pledge => dispatch => {
  dispatch(postUpdatePledge(pledge));
  return service
    .updatePledge(pledge)
    .then(response => {
      dispatch(postUpdatePledgeSuccess(pledge, response));
      dispatch(push('/pledges'));
    })
    .catch(reason => dispatch(postUpdatePledgeError(pledge, reason)));
};

export const createPledge = pledge => dispatch => {
  dispatch(postCreatePledge(pledge));
  return service
    .createPledge(pledge)
    .then(response => {
      dispatch(postCreatePledgeSuccess(pledge, response));
      dispatch(push('/pledges'));
    })
    .catch(reason => dispatch(postCreatePledgeError(pledge, reason)));
};
