import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import user from './user';
import insight from './insight';
import insights from './insights';
import label from './label';
import labels from './labels';
import facts from './facts';
import pledge from './pledge';
import pledges from './pledges';
import dialog from './dialog';
import formatters from './formatters';
import survey from './survey';
import surveys from './surveys';
import track from './track';
import tracks from './tracks';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    user,
    insight,
    insights,
    label,
    labels,
    facts,
    pledge,
    pledges,
    dialog,
    formatters,
    survey,
    surveys,
    track,
    tracks
  });
