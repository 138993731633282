import React, { useState, useEffect } from 'react';
import { isEmpty, padStart } from 'lodash';
import { Button } from '@material-ui/core';

import SurveyQuestion from 'components/views/surveys/question';
import { useDebounce } from 'utils/hooks';
import { idGenerator } from 'utils/generator';
import { NEW_SURVEY_QUESTION, NEW_SURVEY_QUESTION_OPTION } from 'data/surveys';
import useStyles from './Questions.styles';

const Questions = ({ displayAddQuestion = true, initialValue, surveyName, onChange }) => {
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const debouncedQuestions = useDebounce(questions, 500);

  useEffect(() => {
    if (!isEmpty(initialValue) && !gotInitialValue) {
      setQuestions(initialValue);
      setGotInitialValue(true);
    }
  }, [initialValue, gotInitialValue]);
  useEffect(() => {
    if (debouncedQuestions) {
      onChange({ target: { value: questions } });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuestions]);

  const handleChange = questionKey => value => {
    let newQuestions = questions;
    newQuestions[questionKey] = value;
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    const nextQuestion = `${surveyName}_${padStart(questions.length + 2, 3, '0')}`;
    const newQuestion = {
      ...NEW_SURVEY_QUESTION,
      position: questions.length,
      internalId: idGenerator.next().value,
      name: `${surveyName}_${padStart(questions.length + 1, 3, '0')}`,
      defaultNextQuestion: nextQuestion,
      options: [{ ...NEW_SURVEY_QUESTION_OPTION, nextQuestion }]
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleDeleteQuestion = deleteKey => () => {
    const newQuestions = questions.filter((_, questionKey) => questionKey !== deleteKey);
    setQuestions(newQuestions);
  };

  const renderQuestion = (question, questionKey) => {
    const isSelected = questionKey === selectedQuestion;
    const nextQuestion = `${surveyName}_${padStart(questionKey + 2, 3, '0')}`;
    return (
      <SurveyQuestion
        key={question.internalId}
        onChange={handleChange(questionKey)}
        onClick={() => setSelectedQuestion(questionKey)}
        initialValue={question}
        isSelected={isSelected}
        nextQuestion={nextQuestion}
        {...(displayAddQuestion && { onDelete: handleDeleteQuestion(questionKey) })}
      />
    );
  };

  return (
    <>
      {questions.map(renderQuestion)}
      {displayAddQuestion && (
        <div className={classes.addQuestionContainer}>
          <Button color="primary" onClick={handleAddQuestion}>
            Add Question
          </Button>
        </div>
      )}
    </>
  );
};

export default Questions;
